import axios from 'axios';
import APIs from '@services/APIs';

const BASE_URL = APIs.API_URL;

export default {
  getName,
  find,
  verify,
  canHandleCountry,
  mapRemoteList,
  format
};

function getName() {
  return 'loqate';
}

function canHandleCountry() {
  return true;
}

async function find({ address, country, idHash }) {
  const payload = {
    address: address || undefined,
    country,
    idHash
  };

  const source = axios.CancelToken.source();

  try {
    const { data } = await axios.post(`${BASE_URL}/address/autocomplete/${getName()}`, payload, {
      cancelToken: source.token
    });
    if (!data.Items || data.Items.some(({ Error }) => Error)) {
      throw new Error('Error getting address list');
    }
    return mapRemoteList(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function mapRemoteList(data) {
  const addresses = data.Items.filter((item) => {
    return item.Type === 'Address' || item.Type === 'Container';
  });
  return addresses.map(({ Text, Id, Type, Highlight, Description }) => ({
    text: `${Text}${Description ? ` ${Description}` : ''}`,
    globalAddressKey: Id,
    expand: Type === 'Container',
    highlight: Highlight
  }));
}

async function verify({ address, country, idHash }) {
  let addressId = idHash;
  if (!idHash) {
    const addressData = await find({ address, country });
    addressId = addressData?.[0]?.globalAddressKey;
  }

  if (addressId === undefined) {
    return {};
  }

  const payload = {
    address,
    country,
    idHash: addressId
  };

  const source = axios.CancelToken.source();
  return axios
    .post(`${BASE_URL}/address/validate/${getName()}`, payload, { cancelToken: source.token })
    .then(({ data }) => {
      const firstItem = data?.Items?.[0];
      if (firstItem?.Label !== undefined) {
        return format(firstItem, country);
      }
      return {};
    })
    .catch((err) => {
      console.error(err);
      return {};
    });
}

function buildFullAddress(addrObject, { countryIso2Code } = {}) {
  let addressConcatenationOrderMap;
  let mappedData;

  if (countryIso2Code === 'JP') {
    const { SubBuilding = '', Building = '' } = addrObject;
    const unitNumber = SubBuilding || Building;

    mappedData = { ...addrObject, unitNumber };
    addressConcatenationOrderMap = [
      'PostalCode',
      'AdministrativeArea',
      'Locality',
      'Thoroughfare',
      'Premise',
      'unitNumber'
    ];
  }

  if (mappedData && addressConcatenationOrderMap) {
    return addressConcatenationOrderMap
      .flatMap((key) => {
        if (mappedData[key]) {
          return mappedData[key];
        }
        return [];
      })
      .join(' ');
  }

  return addrObject.Label.replaceAll(',', ' ').replaceAll('\n', ' ');
}

function format(addrObject, country) {
  const mappedData = {
    address_line_1: addrObject.Line1 || '',
    address_line_2: addrObject.Line2 || '',
    address_line_3: addrObject.Line3 || '',

    locality: addrObject.City || '',
    locality_town_name: addrObject.City || '',
    suburb: addrObject.District || addrObject.City || '',

    region: addrObject.Province || '',
    postal_code: addrObject.PostalCode || '',
    country: addrObject.CountryName,

    building_number: addrObject.BuildingNumber || '',
    building_name: addrObject.BuildingName || '',

    street: addrObject.Street,
    street_full_name: addrObject.Street || '',

    unit_door_full_number: addrObject.SubBuilding,

    address_api: getName()
  };

  const fullAddress = buildFullAddress(addrObject, { countryIso2Code: country });

  mappedData.fullAddress = fullAddress;
  mappedData.homeAddress = fullAddress;

  return { ...mappedData, provider: getName() };
}
