import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';

import { DEFAULT_LANGUAGE } from '@spotMobileConfig';

/* eslint-disable import/no-unresolved */
import enAU from './compiled/en-au.json';
import enUK from './compiled/en-uk.json';
import enUS from './compiled/en-us.json';

import ar from './compiled/ar.json';
import es from './compiled/es.json';
import fr from './compiled/fr.json';
import hi from './compiled/hi.json';
import id from './compiled/id.json';
import jp from './compiled/jp.json';
import ms from './compiled/ms.json';
import pt from './compiled/pt.json';
import tl from './compiled/tl.json';
import tr from './compiled/tr.json';
import zh from './compiled/zh.json';
import zhh from './compiled/zhh.json';
import vn from './compiled/vn.json';
import ru from './compiled/ru.json';
import sk from './compiled/sk.json';
import cz from './compiled/cz.json';
import de from './compiled/de.json';
import el from './compiled/el.json';
import it from './compiled/it.json';
/* eslint-enable import/no-unresolved */

const ENGLISH_VARIANTS = [
  {
    name: 'en',
    langIsoCode: 'en',
    title: 'English (AU)',
    dict: enAU,
    default: true,
    countryCode: 'AU'
  },
  {
    name: 'en-uk',
    langIsoCode: 'en',
    title: 'English (UK)',
    dict: enUK,
    countryCode: 'GB'
  },
  {
    name: 'en-us',
    langIsoCode: 'en',
    title: 'English (US)',
    dict: enUS,
    default: true,
    countryCode: 'US'
  }
];

const OTHER_LANGUAGES = [
  {
    name: 'zh',
    langIsoCode: 'zh',
    title: 'Mandarin',
    nativeTitle: '中文',
    dict: zh,
    countryCode: 'CN'
  },
  {
    name: 'zhh',
    langIsoCode: 'zh',
    title: 'Cantonese',
    nativeTitle: '粵語',
    dict: zhh,
    countryCode: 'CN'
  },
  {
    name: 'tr',
    langIsoCode: 'tr',
    title: 'Turkish',
    nativeTitle: 'Türkce',
    dict: tr,
    countryCode: 'TR'
  },
  {
    name: 'ar',
    langIsoCode: 'ar',
    title: 'Arabic',
    nativeTitle: 'العربية',
    dict: ar,
    countryCode: 'SA'
  },
  {
    name: 'hi',
    langIsoCode: 'hi',
    title: 'Hindi',
    nativeTitle: 'हिन्दी',
    dict: hi,
    countryCode: 'IN'
  },
  {
    name: 'es',
    langIsoCode: 'es',
    title: 'Spanish',
    nativeTitle: 'Español',
    dict: es,
    countryCode: 'ES'
  },
  {
    name: 'jp',
    langIsoCode: 'ja',
    title: 'Japanese',
    nativeTitle: '日本語',
    dict: jp,
    countryCode: 'JP'
  },
  {
    name: 'ms',
    langIsoCode: 'ms',
    title: 'Malay',
    nativeTitle: 'Melayu',
    dict: ms,
    countryCode: 'MY'
  },
  {
    name: 'id',
    langIsoCode: 'id',
    title: 'Indonesian',
    nativeTitle: 'bahasa Indonesia',
    dict: id,
    countryCode: 'ID'
  },
  {
    name: 'tl',
    langIsoCode: 'tl',
    title: 'Filipino',
    nativeTitle: 'Tagalog',
    dict: tl,
    countryCode: 'PH'
  },
  {
    name: 'pt',
    langIsoCode: 'pt',
    title: 'Portuguese',
    nativeTitle: 'Português',
    dict: pt,
    countryCode: 'BR'
  },
  {
    name: 'fr',
    langIsoCode: 'fr',
    title: 'French',
    nativeTitle: 'Français',
    dict: fr,
    countryCode: 'FR'
  },
  {
    name: 'vn',
    langIsoCode: 'vn',
    title: 'Vietnamese',
    nativeTitle: 'Tiếng Việt',
    dict: vn,
    countryCode: 'VN'
  },
  {
    name: 'ru',
    langIsoCode: 'ru',
    title: 'Russian',
    nativeTitle: 'Русский',
    dict: ru,
    countryCode: 'RU'
  },
  {
    name: 'sk',
    langIsoCode: 'sk',
    title: 'Slovak',
    nativeTitle: 'Slovenčina',
    dict: sk,
    countryCode: 'SK'
  },
  {
    name: 'cz',
    langIsoCode: 'cz',
    title: 'Czech',
    nativeTitle: 'Čeština',
    dict: cz,
    countryCode: 'CZ'
  },
  {
    name: 'de',
    langIsoCode: 'de',
    title: 'German',
    nativeTitle: 'Deutsch',
    dict: de,
    countryCode: 'DE'
  },
  {
    name: 'el',
    langIsoCode: 'el',
    title: 'Greek',
    nativeTitle: 'Νέα Ελληνικά;',
    dict: el,
    countryCode: 'GR'
  },
  {
    name: 'it',
    langIsoCode: 'it',
    title: 'Italian',
    nativeTitle: 'Italiano',
    dict: it,
    countryCode: 'IT'
  }
];

export const LANGUAGES = [...ENGLISH_VARIANTS, ...sortBy(OTHER_LANGUAGES, ['title'])];

const getLanguageItemPerName = (name = '') => {
  return LANGUAGES.find((langItem) => langItem.name.toUpperCase() === name.toUpperCase());
};

export const getDefaultLanguage = () => {
  let defaultLang = getLanguageItemPerName(DEFAULT_LANGUAGE);
  if (!defaultLang) {
    [defaultLang] = LANGUAGES;
  }

  return defaultLang;
};

export const setLanguage = (language) => {
  document.body.dataset.language = language;
  const languageItem = getLanguageItemPerName(language);
  document.documentElement.lang = languageItem.langIsoCode;
};

export const getLanguage = () => {
  let { language } = document.body.dataset;
  const languageItem = LANGUAGES.find((item) => item.name === language);

  if (!language || language.trim().length === 0 || !languageItem) {
    if (language) {
      console.warn('Language', language, 'not found yet. Switching to default language-English');
    }
    language = getDefaultLanguage().name;
  }

  return language;
};

export const localizedString = (name, ...values) => {
  const languageName = getLanguage();
  const languageDict = LANGUAGES.find((item) => item.name === languageName).dict;

  let result = languageDict?.[name];

  if (isNil(result)) {
    const defaultLang = getDefaultLanguage();
    result = isNil(defaultLang?.dict?.[name]) ? name : defaultLang?.dict?.[name];
  }

  if (values && values.length) {
    return values.reduce((finalString, value) => {
      return finalString.replace('{}', value);
    }, result);
  }

  return result;
};

export const isLocalizedStringDefined = (name) => {
  const result = localizedString(name);
  return result && result !== name;
};

export const getLocalizedIdvcContent = (setting) => {
  if (!setting) {
    return null;
  }

  if (isArray(setting)) {
    const currentLanguage = getLanguage();
    const localizedSetting = setting.find((item) => item.language === currentLanguage);

    if (localizedSetting) {
      return localizedSetting;
    }

    if (currentLanguage === DEFAULT_LANGUAGE) {
      // For old settings that did not have language field assumption is that it was english.
      return setting.find((item) => !item.language);
    }
  }

  return setting;
};
