/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../styles/main.scss';

import { Success } from '@Q_ONLY_FLOW/components';
import { Questionnaire } from '@Q_ONLY_FLOW/containers';
import { getCookie, setCookie } from '@js/lib/Utils';
import { withDeviceCheck } from '@lib/components/v2/App/DeviceCheck';
import { compose } from 'redux';
import { withDeviceMotionDetection } from '@lib/components/v2/App/DeviceMotionDetection';
import { withOrientationCheck } from '@lib/components/v2/App/OrientationCheck';
import { withTimeoutCheck } from '@lib/components/v2/App/TimeoutCheck';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completed: false,
      redirect: false
    };
  }

  componentDidMount() {
    const { isCompleted: completed } = this.props;
    this.setState({ completed });

    const transToken = document.querySelector('body').getAttribute('data-id');
    const storedTransToken = getCookie('transToken');

    if (transToken === storedTransToken) {
      if (getCookie('_permission') === 1) {
        // Capturing ID
        setCookie('_permission', 0, -7);
      } else if (getCookie('_permission') === 2) {
        // Liveness step
        setCookie('_permission', 0, -7);
      }
    } else {
      setCookie('transToken', transToken, -7);
    }
  }

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const { questionnaire } = this.props;
    const { completed, redirect } = this.state;

    return (
      <div>
        {!completed && (
          <Questionnaire
            questionnaire={questionnaire}
            onComplete={() => this.setState({ completed: true })}
          />
        )}
        {completed && <Success redirect={redirect} />}
      </div>
    );
  }
}

App.propTypes = {
  isCompleted: PropTypes.bool,
  questionnaire: PropTypes.object
};

export default compose(
  withDeviceMotionDetection,
  withTimeoutCheck,
  withOrientationCheck,
  withDeviceCheck({ checkWebRTC: false }),
  connect(mapStateToProps, null)
)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    idDetails: information.idDetails,
    addresses: information.addresses,
    appConfig
  };
}
